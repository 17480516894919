@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');
@import url('https://fonts.cdnfonts.com/css/noah');

:root {
    --main-text: #002722;
    --main-text-46: rgba(0, 39, 34, 0.46);
    --text-grad-dark: #AC9ACC;
    --text-grad-dark-45: rgba(172, 154, 204, 0.45);
    --text-grad-darker: #ACAACC;
    --text-grad-light: #F7B3E1;
    --text-grad-light-7: rgba(247, 179, 225, 0.7);
    --text-grad-light-5: rgba(247, 179, 225, 0.5);
    --text-grad-light-15: rgba(247, 179, 225, 0.15);
    --purple-dark: #AFABCD;
    --purple-light: #C4B6D9;
    --pink-dark: #FAE1F4;
    --pink-light: #FDE5F6;
    --yellow-light: #F9EFE0;
    --white-9: rgba(255, 255, 255, 0.9);
    --white-7: rgba(255, 255, 255, 0.7);
    --img-bg: #ECECEC;

    --navbar-height: 11.25rem;
    --ph-main: 6.25rem;
    --pt-page-content: 3.75rem;
    --pb-page-content: 7.5rem;

    --border-color-btn-disabled: #ededed;
    --bg-btn-disabled: #fff;
}

.page {
    min-height: 100vh;
    display:flex;
    flex-direction:column;
}

.page-content {
    min-height: var(--navbar-height);
    flex:1;
}

body * {
    margin: 0;
    padding: 0;
    font-family: 'Open Sans', sans-serif;
    color: var(--main-text);
}

body p {
    line-height: 1.25;
    margin: 0;
}

.ph-main {
    padding-left: var(--ph-main);
    padding-right: var(--ph-main);
}

.pt-main {
    padding-top: var(--navbar-height);
}

.pv-page-content {
    padding-top: var(--pt-page-content);
    padding-bottom: var(--pb-page-content);
}

.mt-main {
    margin-top: var(--navbar-height);
}

.styled-input {
    background-color: white;
    padding: 0 1.25rem;
    font-size: 30px;
    border: none;
    line-height: 2;
}

.styled-input.empty {
    color: var(--main-text-46);
}

.styled-input:focus {
    border: none;
    outline: none;
    color: var(--main-text);
}

.styled-input::placeholder {
    color: var(--main-text-46);
}

.bg-gradient-linear-main {
    background-image: linear-gradient(122deg, var(--purple-dark) 5%, var(--pink-light));
}

.text-gradient-linear {
    background-image: linear-gradient(to right, var(--text-grad-light), var(--text-grad-dark) 60%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
}

.text-gradient-linear-dark {
    background-image: linear-gradient(to right, var(--text-grad-light) -15%, var(--text-grad-dark) 15%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
}

.text-gradient-linear-hover:hover {
    background-image: linear-gradient(to right, var(--text-grad-light) -5%, var(--text-grad-dark) 30%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
}

.header {
    text-transform: uppercase;
    font-family: 'Noah', sans-serif;
}

.h-white {
    font-family: 'Noah', sans-serif;
    color: white;
    text-transform: uppercase;
}

@media (max-width: 1000px) {

    :root {
        --navbar-height: 8.75rem;
        --ph-main: 1.25rem;
        --pb-page-content: 3.75rem;
    }
}

@media (max-width: 768px) {
    .ph-main.no-ph-main-768 {
        padding-left: 0;
        padding-right: 0;
    }
}

@media (max-width: 320px) {
    .ph-main.no-ph-main-320 {
        padding-left: 0;
        padding-right: 0;
    }
    :root {
        --navbar-height: 7.5rem;
        --pt-page-content: 2.5rem;
    }
}






