@import "../../../../../utils";


.certificates {
    background-color: var(--main-text);
    padding: 3.75rem 0 3.75rem 6.25rem;
    display: flex;
    justify-content: space-between;
    @include responsive-property('gap', rem(40), [(480px, rem(30))]);
    @include responsive-property-ni('padding-left', [rem(20), (480px, 0)]);
    @include responsive-property-ni('flex-direction', [(480px, column)]);

    &-info {
        flex: 0 0 30%;
        @include responsive-property-ni('padding-left', [(480px, rem(20))]);
        @include responsive-property-ni('padding-right', [(480px, rem(20))]);

        h2 {
            font-family: 'Noah', sans-serif;
            text-transform: uppercase;
            line-height: 1;
            @include responsive-property('margin-bottom', rem(30), [(480px, rem(20)), (380px, rem(10))]);
            @include responsive-property('font-size', 60px, [50px, (380px, 40px)]);
        }

        p {
            color: white;
            font-family: 'Open Sans', sans-serif;
            @include responsive-property('font-size', 20px, [16px]);
        }
    }

    &-gallery {
        flex: 0 1 55%;
        overflow-x: auto;
        position: relative;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar{
            display: none;
        }

        &-shadow {
            position: absolute;
            height: 100%;
            width: 50px;
            background-image: linear-gradient(to right, var(--main-text), rgba(0,0,0,0));
            @include responsive-property-ni('display', [(480px, none)]);
        }

        &-grid {
            padding: 0 3.75rem;
            display: grid;
            grid-auto-flow: column;
            @include responsive-property('grid-column-gap', rem(50), [rem(30), (380px, rem(20))]);
            overflow-x: auto;
            -ms-overflow-style: none;
            scrollbar-width: none;

            img {
                @include responsive-property-ni('height', [170px, (380px, 120px)]);
            }

            &::-webkit-scrollbar{
                display: none;
            }
        }
    }
}
