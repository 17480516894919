@import "../../../utils";


.footer {
    background-color: var(--main-text);
    padding-top: rem(60);
    @include responsive-property('padding-bottom', rem(70), [rem(100), rem(60)]);
    @include responsive-property('grid-template-columns', 4fr 3fr 5fr, [#{1fr 1fr}, #{1fr 2fr}, #{1fr 1fr}, (370px, 1fr)]);
    display: grid;
    grid-gap: rem(20);
    
    ul {
        list-style: none;
        padding-left: 0;
        padding-top: rem(50);
        margin: 0;
        @include responsive-property-ni('display', [none]);

        li {
            color: var(--yellow-light);
            font-size: 20px;
            font-weight: bold;
        }

        a {
            text-decoration: none;
        }
    }

    >:nth-child(1) {
        @include responsive-property-ni('justify-self', [center, start]);
    }
    
    &-info {
        display: grid;
        @include responsive-property('grid-template-columns', 1fr 1fr, [(480px, 1fr)]);
        grid-column-gap: rem(20);
        grid-row-gap: rem(40);

        * {
            font-size: 20px;
            color: var(--yellow-light);
        }

        h4 {
            margin-bottom: rem(20);
        }

        > :last-child p:not(:last-child) {
            @include responsive-property('margin-bottom', 1.5rem, [(480px, 0)]);
        }

        p {
            line-height: 1.5;
            @include responsive-property('margin-bottom', 1rem, [(480px, 0)]);
        }
    }
}
