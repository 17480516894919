@import "../../../utils";


.nav-bar {
    position: absolute;
    width: 100%;
    z-index: 200;
    @include responsive-properties(['padding-top', 'padding-bottom'], rem(60), [rem(40)]);
    @include responsive-property-ni('flex-direction', [(810px, column)]);

    &-bg {
        position: absolute;
        height: var(--navbar-height);
        inset: 0;
    }

    &-menu {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &-menu-dropdown {
        z-index: 300;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        display: grid;
        grid-template-rows: 0fr;
        transition: grid-template-rows 250ms ease-out;

        > div {
            overflow: hidden;
        }

        &.active {
            grid-template-rows: 1fr;
        }
    }

    &-section {
        display: flex;
        flex-direction: column;
        gap: .5rem;
        flex-grow: 0.3;
    }

    &-items {
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: space-evenly;

        li {
            line-height: 1;
            list-style: none;
            padding: 0 1.2em;
            text-align: center;
        }

        a {
            text-decoration: none;
            display: block;
        }
    }

    .bar {
        height: 1px;
        display: block;
        width: 100%;
        background-color: var(--main-text);
    }
}

.hamburger {
    cursor: pointer;
    @include responsive-property-ni('flex-direction', [(810px, column)]);
    @include responsive-property-ni('gap', [(810px, 10px)]);
    @include responsive-property('display', none, [(810px, flex)]);

    .line {
        display: block;
        @include responsive-property('width', 50px, [(320px, 30px)]);
        height: 3px;
        transition: all 0.3s ease-in-out;
        background-color: var(--main-text);
    }
}

.dropdown-shadow {
    height: 460px;
    background-image: radial-gradient(ellipse 200% 200% at 50% -40%, var(--text-grad-light), rgba(0,0,0,0) 60%);
}

@media (max-width: 810px) {

    .nav-bar-items {
        background-color: white;
        padding: rem(40) rem(20) rem(20);
        flex-direction: column;
        gap: rem(40);
        position: relative;
        align-items: flex-start;

        li {
            font-size: 30px;
            padding: 0;
            text-align: start;
        }

        a {
            width: 100%;
        }
    }

    .nav-bar-section {
        display: none;
    }

    .hamburger.active .line:nth-child(2) {
        opacity: 0;
    }

    .hamburger.active .line:nth-child(1) {
        transform: translateY(13px) rotate(45deg);
    }

    .hamburger.active .line:nth-child(3) {
        transform: translateY(-13px) rotate(-45deg);
    }
}
