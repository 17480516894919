@import "../../../../../utils";

.titled-section {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-areas:
            "title   btn"
            "section section"
            "btn-sm  btn-sm";

    &-title {
        grid-area: title;
        align-self: center;
        @include responsive-property('font-size', 60px, [(320px, 40px)]);
        line-height: 1;
        font-family: 'Noah', sans-serif;
        text-transform: uppercase;
        margin: 0;
    }

    > button {
        @include responsive-property('grid-area', btn, [(480px, btn-sm)]);
        @include responsive-property('justify-self', flex-end, [(320px, center)]);
        @include responsive-property-ni('margin-top', [(480px, rem(40))]);
        align-self: center;
    }

    &-section {
        grid-area: section;
    }
}
