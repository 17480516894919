@import "../../../utils";

.main-button {
    @include responsive-property('width', rem(240), [rem(224), (480px, rem(210))]);
    height: rem(60);
    text-align: center;
    font-size: 20px;
    border-radius: rem(30);
    background: none;
    flex-shrink: 0;

    &:not(:disabled):hover {
        background: linear-gradient(45deg, var(--text-grad-dark), var(--text-grad-light));
        background-size: 400% 400%;
        -webkit-animation: gradient 5s ease infinite;
        -moz-animation: gradient 5s ease infinite;
        -o-animation: gradient 5s ease infinite;
        animation: gradient 5s ease infinite;
    }

    &.light {
        color: var(--yellow-light);
        border: 3px solid var(--yellow-light);

        &:not(:disabled):active {
            border-color: var(--pink-light);
            color: var(--pink-light);
        }
    }

    &.dark {
        color: var(--main-text);
        border: 3px solid var(--main-text);

        &:not(:disabled):active {
            border-color: var(--main-text-46);
            color: var(--main-text-46);
        }
    }

    &.filled {
        background-color: var(--main-text);

        &:disabled {
            background-color: var(--bg-btn-disabled);
            border-color: var(--border-color-btn-disabled);
            color: var(--main-text-46);
        }

    }

}

@-webkit-keyframes gradient {
    0%{background-position:0 45%}
    50%{background-position:100% 56%}
    100%{background-position:0 45%}
}
@-moz-keyframes gradient {
    0%{background-position:0 45%}
    50%{background-position:100% 56%}
    100%{background-position:0 45%}
}
@-o-keyframes gradient {
    0%{background-position:0 45%}
    50%{background-position:100% 56%}
    100%{background-position:0 45%}
}
@keyframes gradient {
    0%{background-position:0 45%}
    50%{background-position:100% 56%}
    100%{background-position:0 45%}
}
