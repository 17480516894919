@import "../../../utils";

.appointment {
    background-image: linear-gradient(135deg, var(--purple-light) 15%, var(--pink-dark));
    position: relative;
    overflow: hidden;
    @include responsive-properties(['padding-top', 'padding-bottom'], rem(120) , [(768px, rem(60))]);

    &-bg-img {
        position: absolute;
        mix-blend-mode: overlay;
        opacity: .6;
        @include responsive-property('top', 3%, [8%, (480px, 10%), (320px, 25%)]);
        @include responsive-property('right', -25%, [-35%, (480px, -60%)]);
        @include responsive-property-ni('width', [1015px, 783px, (320px, 487px)]);
    }

    &-grid {
        @include responsive-property('display', grid, [(480px, block)]);
        @include responsive-property('grid-template-columns', 2fr 1fr, [#{3fr 1fr}, #{5fr 1fr}]);
        position: relative;
    }

    h2 {
        position: relative;
        @include responsive-property('font-size', 60px, [50px, (380px, 40px)]);
        @include responsive-property('margin-bottom', rem(40), [rem(20), (380px, rem(10))]);
        @include responsive-property-ni('text-align', [(320px, center)]);
    }

    p {
        position: relative;
        @include responsive-property('font-size', 30px, [(320px, 20px)]);
        @include responsive-property-ni('text-align', [(320px, center)]);
        @include responsive-property('margin-bottom',rem(40), [rem(20)]);
        color: white;
    }

    &-form {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: rem(20);
        margin-bottom: rem(40);

        &-btn-row {
            display: flex;
            @include responsive-property-ni('justify-content', [(480px, flex-end), (320px, center)])
        }

        textarea {
            resize: none;
        }

        input, textarea {
            @include responsive-property-ni('font-size', [(320px, 16px)])
        }
    }

}