@import "../../../../../utils";

.doctors-home {
  position: relative;
  overflow: hidden;
  padding-bottom: rem(120);
  background-image: radial-gradient(ellipse 75% 70% at 50% 80%, var(--text-grad-dark), white );

  &-bg-img {
    display: none;
  }

  > div {
    position: relative;
  }

  &-section {
    padding-top: rem(60);
    display: flex;
    flex-direction: column;
    gap: rem(60);

    &-text {
      font-size: 30px;
      color: white;
    }
  }

  &-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: rem(20);
    grid-auto-rows: 1fr;
  }

}

.doctors-home .doctor-card {
  background-color: var(--main-text);

  &-img-wrapper {
    height: rem(400);
    overflow: hidden;
    position: relative;
    background-color: var(--img-bg);
  }

  &-img {
    position: absolute;
    top: rem(50);
    object-position: top;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translateX(-50%) scale(1.2);
    max-width: 450px;
  }

  &-info {
    padding: rem(20) rem(20) rem(40);
    display: flex;
    justify-content: space-between;

    h2 {
      color: var(--yellow-light);
      font-family: 'Noah', sans-serif;
      font-size: 30px;
      line-height: 1.5;
      margin: 0;
    }

    &-exp {
      display: flex;
      flex-direction: column;

      &-years {
        text-align: center;
        font-family: 'Noah', sans-serif;
        font-size: 100px;
        margin: 0;
        line-height: 1;
      }

      span {
        font-size: 20px;
        text-align: center;
        font-family: 'Noah', sans-serif;
        color: var(--yellow-light);
      }

    }

  }

}

@media (max-width: 1000px) {

  .doctors-home {

    &-section-text {
      font-size: 20px;
      color: white;
    }

    .doctor-card-info {
      h2 {
        font-size: 20px;
      }

      &-exp-years {
        font-size: 80px;
      }
    }

  }

}

@media (max-width: 768px) {

  .doctors-home {
    padding-bottom: rem(60);

    &-bg-img {
      display: block;
      position: absolute;
      right: -45%;
      bottom: -2%;
      transform: rotate(-105.47deg);
      opacity: .07;
    }

    &-grid {
      grid-template-columns: repeat(2, 1fr);
    }

    &-section {
      flex-direction: column-reverse;
      gap: rem(20);
      padding-top: rem(40);

      &-text {
        color: var(--main-text);
        font-size: 16px;
      }
    }

    .doctor-card-info {

      h2 {
        font-size: 20px;
      }

      &-exp-years {
        font-size: 80px;
      }
    }

  }

}

@media (max-width: 480px) {
  .doctors-home {
    &-grid {
      grid-template-columns: 1fr;
      grid-auto-rows: auto;
    }

    &-section {
      padding-top: rem(20);
      gap: rem(40);
    }

    &-bg-img {
      display: none;
    }
  }
}

@media (max-width: 320px) {

  .doctor-card-info {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .doctors-home .doctor-card-img-wrapper {
    height: rem(280);
  }
}
