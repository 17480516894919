@import "../../../../../utils";

.consulting {
    background-image: linear-gradient(150deg, var(--text-grad-darker) 30%, var(--pink-light) 80%);
    @include responsive-property('margin-top', rem(180), [(768px, rem(60)), (320px, rem(50))]);
    @include responsive-property('margin-bottom', rem(180), [(768px, rem(80)), (480px, rem(60))]);
    @include responsive-property('padding-top', rem(60), [(480px, rem(40)), (320px, rem(50))]);
    @include responsive-property('padding-bottom', rem(60), [(320px, rem(50))]);
    @include responsive-properties(['padding-left', 'padding-right'], rem(100), [(768px, rem(20))]);

    h2 {
        line-height: 1.3;
        margin: 0;
        @include responsive-property('font-size', 60px, [50px, (480px, 40px), (320px, 30px)]);
        @include responsive-property-ni('text-align',[(320px, center)]);
    }

    .phone-section {
        display: flex;
        justify-content: space-between;
        @include responsive-property('align-items', center, [(480px, flex-end), (320px, center)]);
        @include responsive-property('padding-top', rem(40), [(768px, rem(20)), (320px, rem(10))]);
        @include responsive-property('gap', rem(20), [(480px, rem(30)), (320px, rem(20))]);
        @include responsive-property-ni('flex-direction', [(480px, column)]);

        input {
            max-width: 45rem;
            width: 100%;
            @include responsive-property-ni('font-size', [(480px, 20px), (320px, 16px)]);
        }
    }
}
