@import "../../../../../utils";

.about-home {
    background-color: var(--main-text);
    @include responsive-property('min-height', rem(600), [(768px, rem(450))]);
    overflow: hidden;
    position: relative;

    &-img-wrapper {
        overflow: hidden;
        @include responsive-property-ni('position', [(585px, relative)]);
        @include responsive-property-ni('height', [(585px, rem(450)), (320px, rem(320))]);

        img {
            position: absolute;
            transform: rotate(-3.14deg);
            @include responsive-property('top', rem(-304), [rem(-192), rem(-170), (820px, rem(-120)), (585px, rem(-186)), (620px, rem(-116)), (320px, rem(-240))]);
            @include responsive-property('left', rem(-416), [rem(-362), rem(-372), (585px, rem(-300)), (320px, rem(-236))]);
            @include responsive-property-ni('width', [1186px, 1033px, (320px,  929px)]);
            @include responsive-property('mask-image', radial-gradient(ellipse 80% 100% at 50% 70%, black 10%, rgba(0,0,0,0) 60%),
            [
            radial-gradient(ellipse 45% 100% at 50% 70%, black 10%, rgba(0,0,0,0) 70%),
            radial-gradient(ellipse 50% 100% at 50% 70%, black 10%, rgba(0,0,0,0) 70%),
            (585px, radial-gradient(ellipse 55% 100% at 40% 65%, black 10%, rgba(0,0,0,0) 70%)),
            (320px, radial-gradient(ellipse 55% 100% at 40% 65%, black 10%, rgba(0,0,0,0) 55%))
            ]);
        }
    }

    &-info {
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 999;
        @include responsive-property('padding-top', rem(120), [(768px, rem(60)), (585px, rem(20))]);

        &-grid {
            display: grid;
            @include responsive-property('grid-column-gap', rem(20), [(585px, 0)]);
            @include responsive-property('grid-template-columns',  5fr 7fr, [#{3fr 4fr 1fr}, #{1fr 2fr}, (585px, 1fr)]);
            @include responsive-property('grid-row-gap', rem(40), [(585px, rem(20))]);

            .title {
                @include responsive-property('font-size', 40px, [(320px, 30px)]);
                text-transform: uppercase;
                font-family: 'Noah', sans-serif;
                color: var(--text-grad-light);
                grid-column: 2;
                margin: 0;
            }

            .text {
                @include responsive-property('font-size', 20px, [16px]);
                color: var(--yellow-light);
                grid-column: 2;
            }
        }

        &-btn {
            grid-column: 2;
            display: flex;
            @include responsive-property-ni('margin-top', [rem(40), rem(10), 0, rem(20)]);
            @include responsive-property('justify-content', flex-end, [(320px, center)]);
            margin-bottom: rem(60);
        }
    }
}
