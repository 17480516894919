
.logo {
    cursor: pointer;
}

@media (max-width: 320px) {
    .logo-sm {
        width: 130px;
    }
}
