@function rem($pixels) {
  @return #{calc($pixels / 16)}rem;
}

@mixin responsive-properties-ni($properties, $values: []) {
  @if length($values) > 0 {
    $breakpoints: [1000px, 768px, 480px, 320px];
    $i: 1;
    @each $value in $values {
      @if length($value) == 1 and $i <= length($breakpoints) {
        @media (max-width: nth($breakpoints, $i)) {
          @each $property in $properties {
            #{$property}: $value;
          }
        }
        $i: $i + 1;
      } @else if length($value) == 2 {
        @media (max-width: nth($value, 1)) {
          @each $property in $properties {
            #{$property}: nth($value, 2);
          }
        }
      }
    }
  }
}

@mixin responsive-properties($properties, $initial, $values: []) {
  @each $property in $properties {
    #{$property}: $initial;
  }
  @include responsive-properties-ni($properties, $values);
}

@mixin responsive-property-ni($property, $values: []) {
  @include responsive-properties-ni([$property], $values);
}

@mixin responsive-property($property, $initial, $values: []) {
  @include responsive-properties([$property], $initial, $values);
}

