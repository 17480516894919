@import "../../../utils";


.contacts-page {
  background-image: radial-gradient(circle at 50% 150%, var(--text-grad-light-7) 20%, var(--white-7) 80%);

  .titled-section-title {
    @include responsive-property('margin-bottom', rem(60), [rem(30), (320px, rem(20))]);

  }

  .contacts-grid {
    display: grid;
    grid-gap: rem(20);
    @include responsive-property('grid-template-columns', 2fr 1fr 1fr, [#{3fr 2fr 3fr}, repeat(3, 1fr), (600px, repeat(2, 1fr)), (440px, 1fr)]);
  }

  .contact {

    * {
      font-weight: bold;
      text-transform: uppercase;
    }

    h4 {
      font-size: 30px;
      font-weight: bold;
      font-family: 'Noah', sans-serif;
      line-height: 1.2;
    }

    a[href^='mailto:'] {
      color: inherit;
      text-decoration: none;
    }

    p {
      line-height: 1.5;
      margin-bottom: 1rem;
    }

  }
}

.map-container {
  width: 100%;
  @include responsive-property('height', rem(340), [(320px,rem(280))]);
  @include responsive-property('margin-bottom', rem(60), [rem(40), (320px,rem(30))]);
}
