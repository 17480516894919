@import "../../../../../utils";

.title-container {
    position: relative;
    overflow: hidden;

    .img-arrow {
        position: absolute;
        transform: rotate(-172.53deg);
        @include responsive-property('top', 55%, [60%, 65%, (320px, 75%)]);
        @include responsive-property('left', 24%, [15%, 20%, 38%, 65%]);
        @include responsive-property-ni('width', [553px, 509px, 404px, 171px]);
    }

    .img-cross {
        position: absolute;
        transform: rotate(20.34deg);
        @include responsive-property('top', 25%, [(768px, 14%), (320px, 25%)]);
        @include responsive-property('right', -10%, [-18%, -20%, -35%, -25%]);
        @include responsive-property-ni('width', [520px, 479px, 404px, 206px]);
    }


}

.title-section {
    position: relative;
    @include responsive-property('padding-top', rem(60), [rem(100), rem(40), (320px, 0)]);
    @include responsive-property('padding-bottom', rem(180), [(768px, rem(120)), (320px, rem(60))]);

    .title {
        margin: 0;
        @include responsive-property('font-size', 90px, [70px, (420px, 40px)]);
    }

    .subtitle {
        font-family: 'Noah', sans-serif;
        @include responsive-property('font-size', 40px, [(420px, 30px)]);
        margin: 2.5rem 0 3.75rem 0;
    }

    .title-row {
        display: grid;
        @include responsive-property('grid-template-columns', 1fr, [(768px, #{2fr 1fr})]);
    }

    .button-row {
        display: flex;
        @include responsive-property-ni('justify-content', [(320px, center)]);
    }

}
