@import "../../../utils";

.doctors-page {
  background-image: radial-gradient(ellipse, var(--text-grad-dark), white 90%);

  .doctors {
    @include responsive-property('padding-bottom', rem(120), [rem(120), rem(155), rem(120), rem(60)]);

    .titled-section-title {
      @include responsive-property('margin-bottom', rem(50), [rem(30), (480px, rem(20))]);
    }

    &-section {
      display: flex;
      @include responsive-property('flex-direction', column, [(480px, column-reverse),]);
      @include responsive-property('gap', rem(40), [rem(40), rem(50), rem(40), rem(20)]);
    }

    &-col {
      display: flex;
      flex-direction: column;
      @include responsive-property('gap', rem(60), [rem(50), rem(20), rem(30), rem(20)]);
    }

    &-text {
      @include responsive-property('font-size', 30px, [(480px, 16px),]);
    }

  }
}



.doctors-page .doctors .doctor-card {
  display: grid;
  background-color: var(--main-text);
  @include responsive-property('grid-template-columns', 1fr 2fr, [#{3fr 5fr}, #{1fr 2fr}, 1fr]);


  &-info {
    @include responsive-property('padding-top', rem(40), [(480px, rem(20))]);
    @include responsive-property('padding-left', rem(60), [rem(40), rem(20), (320px, rem(10))]);
    @include responsive-property('padding-right', rem(60), [rem(20), (320px, rem(10))]);
    @include responsive-property('padding-bottom', rem(60), [rem(40)]);

    h2 {
      font-family: 'Noah', sans-serif;
      text-transform: uppercase;
      margin-bottom: rem(20);
      line-height: 1.3;
      @include responsive-property('font-size', 30px, [(320px, 20px)]);
    }

    &-exp {
      color: var(--yellow-light);
      margin-bottom: rem(20);
      @include responsive-property('font-size', 30px, [(320px, 20px)]);
    }

    &-text {
      color: white;
      @include responsive-property('font-size', 20px, [(768px, 16px)]);
    }
  }

  &-img-wrapper {
    position: relative;
    overflow: hidden;
    background-color: var(--img-bg);
    @include responsive-property('min-height', 400px, [(320px, 280px)]);

    img {
      position: absolute;
      top: rem(50);
      left: 50%;
      transform: translateX(-50%) scale(1.2);
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
      max-width: 450px;
    }
  }
}

.doctors-page .reviews {

  .titled-section-title {
    @include responsive-property('margin-bottom', rem(60), [(768px, rem(40)), (320px, rem(20))]);
  }

  &-grid {
    display: grid;
    grid-gap: rem(20);
    @include responsive-property('grid-template-columns', repeat(3, 1fr), [repeat(2, 1fr), (480px, 1fr)]);

    > :first-child {
      @include responsive-property('grid-column', auto, [#{ 1 / 3 }, (480px, 1)]);
      @include responsive-property('box-shadow', none, [(768px, 9px 12px 21px -1px rgba(0,0,0,.11))]);

      .review-card-header-date {
        @include responsive-property('display', block, [(768px, none)]);
      }
    }

    .review-card {
      @include responsive-property('padding', rem(40) rem(20) rem(60), [#{rem(40) rem(20) rem(50)}, #{rem(20) rem(20) rem(40)}, (320px, rem(20) rem(16) rem(40))]);

      &-header-time {
        display: none;
      }

      p {
        font-size: 16px;
      }
    }
  }
}
