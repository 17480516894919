@import "../../../../../utils";


.reviews-home {
    @include responsive-property('padding-top', rem(120), [(768px, rem(60))]);
    @include responsive-property('padding-bottom', rem(180), [(768px, rem(60))]);
    background-image: radial-gradient(circle at 50% 0, var(--text-grad-dark), white);

    &-section {
        @include responsive-property('padding-top', rem(60), [(768px, rem(40)), (320px, rem(30))]);
    }

    &-grid {
        display: grid;
        @include responsive-property('grid-template-columns', repeat(2, 1fr), [(480px, 1fr)]);
        @include responsive-property('grid-auto-rows', 1fr, [(480px, auto)]);
        grid-gap: 1.25rem;
    }

    .titled-section button {
        @media (max-width: 520px) {
            margin-top: 2.5rem;
            grid-area: btn-sm;
        }
    }

}