@import "../../../utils";

.review-card {

    @include responsive-property('padding', rem(40), [#{rem(20) rem(20) rem(40)}, (320px, rem(20) rem(16) rem(40))]);
    background-color: white;

    &-header {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        @include responsive-property('margin-bottom',rem(40), [rem(20)]);
        @include responsive-property('flex-direction',row, [(320px, column)]);
        @include responsive-property('align-items',center, [(320px, start)]);

        h2 {
            font-family: 'Noah', sans-serif;
            font-size: 40px;
            margin: 0;
        }

        &-time {
            @include responsive-property('display',inline, [(768px, none)]);
        }

    }

    p {
        @include responsive-property('font-size', 20px, [16px]);
        font-style: italic;
    }

    &-shadow {
        box-shadow: 9px 12px 21px -1px rgba(0,0,0,.11);
    }

}


.photo-review-card {
    display: flex;
    @include responsive-property('margin-bottom', rem(20), [(320px, rem(50))]);
    @include responsive-property('flex-direction', row, [(480px, column)]);

    background-color: white;


    &-img-wrapper {
        position: relative;
        overflow: hidden;
        @include responsive-property('width', rem(400), [ rem(220), rem(270), 100%]);
        aspect-ratio: 1 / 1;
        flex-shrink: 0;

        img {
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }
}
