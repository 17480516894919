@import "../../../utils";

.services-page {
  overflow: hidden;
  position: relative;
  background-image: radial-gradient(ellipse at 50% 60%, var(--text-grad-light-7), var(--white-7));

  .titled-section-title {
    @include responsive-property('margin-bottom', rem(60), [rem(30), (320px, rem(10))]);
  }

  .services-grid {
    display: grid;
    grid-auto-rows: auto;
    @include responsive-property('grid-template-columns', 1fr 2fr, [#{3fr 5fr}, 1fr]);
    grid-gap: rem(20);

    > :last-child {
      @include responsive-property-ni('display', [(480px, none)]);
    }

  }

  .services-accordion {
    background-color: white;
    padding-top: rem(30); // 10 more pixels come from item's padding-top
    padding-bottom: rem(30); // 10 more pixels come from item's padding-top
    @include responsive-property-ni('display', [(768px, grid), (480px, initial)]);
    grid-column-gap: rem(40);
    grid-template-columns: 1fr 1fr;

    &-item {

      &-header {
        cursor: pointer;
        padding-top: rem(10);
        padding-bottom: rem(10);
        @include responsive-properties(['padding-left', 'padding-right'], rem(40), [rem(20), (320px, rem(10))]);

        h4 {
          font-weight: bold;
          font-size: 18px;
          margin: 0;
        }

        .plus-marker {
          font-size: 30px;
          font-family: 'Noah', sans-serif;
          font-weight: bold;
          line-height: 0.1;
          transition: transform 250ms linear;
          @include responsive-property('display', none, [(480px, block)]);

          &.open {
            transform: rotate(45deg);
          }

        }

      }

      &-body {
        display: grid;
        grid-template-rows: 0fr;
        transition: grid-template-rows 250ms ease-in-out;
        @include responsive-properties(['padding-left', 'padding-right'], rem(40), [rem(20), (320px, rem(10))]);

        .content-wrapper {
          overflow: hidden;
        }

        &-content {
          padding-top: rem(10);
          @include responsive-property('padding-bottom', rem(30), [rem(20), (320px, rem(15))]);

          ul {
            padding-left: rem(20);
            margin: 0;
            list-style-position: outside;

            li {
              cursor: pointer;
            }

          }

        }

        &-info {
          @include responsive-property('display', none, [(768px, block)]);
        }

        &.open {
          grid-template-rows: 1fr;
          @media (max-width: 768px) and (min-width: 481px) {
            display: none;
          }
        }

      }

    }

    &-side-panel {
      @include responsive-property('display', none, [(768px, block), (480px, none)]);
      padding-right: rem(20);
    }

  }

  .img-1 {
    position: absolute;
    opacity: .5;
    transform: rotate(-57.07deg);
    @include responsive-property('top', rem(-50), [0]);
    @include responsive-property('right', -16%, [-39%, -50%, -20%, -30%]);
    @include responsive-property-ni('width', [(480px, rem(287)), (320px, rem(216))]);

  }

  .img-2 {
    position: absolute;
    opacity: .5;
    transform: rotate(-38.13deg);
    @include responsive-property('top', rem(205), [rem(239), rem(415), rem(95), rem(188)]);
    @include responsive-property('left', -17%, [-24%, -22%, -24%, -30%]);
    @include responsive-property-ni('width', [(768px, rem(794)), (480px, rem(473)), (320px, rem(379))]);
  }

}

.service-name {

  &.open {
    font-weight: bold;
  }

  &:not(.open):hover {
    color: var(--main-text-46);
  }

}

.service-info {

  @include responsive-property('padding-top', rem(40), [(480px, rem(20)), (320px, rem(5))]);
  @include responsive-properties(['padding-left', 'padding-right'], rem(40), [rem(20), (480px, 0)]);
  @include responsive-property('padding-bottom', rem(80), [rem(40), (480px, rem(30)), (320px, rem(10))]);

  background-color: white;

  h2 {
    text-transform: uppercase;
    margin-bottom: rem(20);
    font-weight: bold;
    font-size: 18px;
  }

  ul {
    margin: 0;
  }

}


