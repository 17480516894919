@import "../../../../../utils";

.services-home {
    background-image: radial-gradient(ellipse 200% 200% at 50% -60%, var(--text-grad-light-7), var(--white-7) 80%);
    padding-top: 3.75rem;
    position: relative;
    overflow: hidden;

    .titled-section button {
        @include responsive-property-ni('margin-top', [(590px, rem(40))]);
        @include responsive-property-ni('grid-area', [(590px, btn-sm)]);
    }

    .services-grid {
        @include responsive-property('padding-top', rem(60), [(768px, rem(40)), (480px, rem(20)), (320px, rem(40))]);
        @include responsive-property('grid-template-columns', repeat(3, 1fr), [(768px, #{1fr 1fr}), (480px, 1fr)]);
        @include responsive-property('grid-auto-rows', minmax(rem(340), 1fr), [(480px, auto)]);
        display: grid;
        grid-gap: rem(20);

    }

    .services-img {
        position: absolute;
        transform: rotate(-21.35deg);
        opacity: 0.35;
        @include responsive-property('right', -25%, [-50%, -60%, -155%]);
        @include responsive-property-ni('top', [(480px, 10%)]);
    }

    > div {
        position: relative;
    }
}

.services-card {
    background-color: var(--white-9);
    padding: 1.25rem;

    &-title {
        font-size: 20px;
        font-family: 'Noah', sans-serif;
        text-transform: uppercase;
        font-weight: bold;
        margin-bottom: 1.25rem;
        @include responsive-property('font-size', 20px, [16px, 20px, (320px, 16px)]);
    }

    ul {
        margin: 0;
        list-style-position: outside;

        li {
            cursor: pointer;

            &:hover {
                color: var(--main-text-46);
            }

        }

    }
}
