@import "../../../utils";

.prices-page {
  position: relative;
  overflow: hidden;
  background-image: radial-gradient(ellipse 4200px 2100px at 50% 0, var(--text-grad-light-5), var(--text-grad-light-15), var(--text-grad-dark-45) 80%, var(--text-grad-light-15));
  //background-image: radial-gradient(ellipse 4200px 2100px at 50% 0, var(--text-grad-light-5), var(--text-grad-light-15), var(--text-grad-dark-45) 90%);
  //background-image: radial-gradient(ellipse 300% 150% at 50% 0, var(--text-grad-light-7), var(--white-7), var(--text-grad-dark-7) 90%);

  > div {
    position: relative;
  }

  .img-1 {
    opacity: .35;
    transform: rotate(-117.78deg);
    position: absolute;
    @include responsive-property('width', rem(937), [rem(754), (480px, rem(489))]);
    @include responsive-property('right', -23%, [-44%]);
    @include responsive-property('top', rem(500), [rem(300), (480px, rem(425))]);
    @include responsive-property-ni('display', [(320px, none)]);
  }

  .img-2 {
    transform: rotate(-38.16deg);
    position: absolute;
    @include responsive-property('width', rem(1644), [(768px, rem(1136)), (768px, rem(854))]);
    @include responsive-property('left', -30%, [-50%, (480px, -80%)]);
    @include responsive-property('top', rem(2000), [rem(1700), (480px, rem(1900))]);
    @include responsive-property-ni('display', [(320px, none)]);
  }

  .img-3 {
    transform: rotate(-80.63deg);
    position: absolute;
    @include responsive-property-ni('width', [rem(741), rem(996), rem(674)]);
    @include responsive-property('right', -20%, [(768px, -65%), (480px, -55%)]);
    @include responsive-property('top', rem(3800), [rem(4150), rem(3850), rem(5550)]);
    @include responsive-property-ni('display', [(320px, none)]);
  }

  .titled-section-title {
    @include responsive-property('margin-bottom', rem(40), [rem(20)])
  }

  &-text {
    @include responsive-property('font-size', 20px, [rem(20), (320px, 16)]);
    @include responsive-property('padding-bottom', rem(50), [rem(40), rem(45), rem(20), rem(40)]);
    @include responsive-property('display', grid, [block]);
    grid-template-columns: 3fr 1fr;

  }

  .prices-search {
    display: flex;
    justify-content: space-between;
    @include responsive-property-ni('flex-direction', [(480px, column)]);
    @include responsive-property('align-items', center, [(480px, start), (320px, center)]);
    gap: rem(20);
    @include responsive-property('margin-bottom', rem(60), [(768px, rem(40))]);

    input {
      @include responsive-property-ni('width', [(480px, 100%)]);
      min-width: 20%;
      flex-grow: .5;
      flex-shrink: 1;
    }

    > * {
      display: block;
    }
  }

  .prices-accordion {
    display: flex;
    flex-direction: column;
    @include responsive-property('gap', rem(40), [(768px, rem(20))]);

    &-item {
      background-color: white;
      @include responsive-property('padding', rem(40) rem(30), [(768px, rem(20))]);

      > h2 {
        font-weight: bold;
        font-size: 30px;
        text-transform: uppercase;
        cursor: pointer;
        margin: 0;
      }

      &-body {
        display: grid;
        grid-template-rows: 0fr;
        transition: grid-template-rows 250ms ease-in-out;

        &-content {
          overflow: hidden;

          .prices-list {
            display: flex;
            flex-direction: column;
            gap: rem(10);

            > :first-child {
              @include responsive-property('padding-top', rem(20), [(768px, rem(10))]);
            }

            &-item {
              position: relative;

              * {
                @include responsive-property('font-size', 18px, [(480px, 16px)]);
              }

              &-title {
                display: flex;
                @include responsive-property('gap', rem(40), [(320px, rem(20))]);
                justify-content: space-between;
                align-items: flex-end;
              }

              .leaders {
                position: absolute;
                bottom: rem(4);
                left: 0;
                width: 100%;
                height: 2px;
                background-image: linear-gradient(to right, var(--text-grad-light), var(--text-grad-dark));
              }

              .name {
                padding-right: rem(8);
                position: relative;
                background-color: white;
              }

              .price {
                padding-left: rem(8);
                position: relative;
                font-weight: bold;
                background-color: white;
                white-space: nowrap;
              }

            }

          }

        }

        &.open {
          grid-template-rows: 1fr;
        }

      }

    }

  }

}
